/**
* _style.scss v-1.01
* enetech.fr
*/


// -------------------------  body ---/
// -------------------------  masthead ---/
// -------------------------  precontent ---/

#top{
  padding: rem-calc(0);
  } // #top


// -------------------------  main ---/
#main{
  padding-top: $sticky-shrink-mobmenu-height;
  @include breakpoint(large) {
    padding-top: $sticky-shrink-menu-height;
  }



}

.lead {
  padding-bottom:rem-calc(18);
  font-weight: 600;
}

.col4 {
  color: $col4;
}

.col3 {
  color: $col3;
}



// -------------------------  main/presentaion ---/

.present1{
  padding: rem-calc(6 0);
  display: flex;
  flex-flow: row;
  //justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;  
  justify-content: flex-start;
  //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
  align-items: center;

  img {
      padding-left: rem-calc(0);
      padding-right: rem-calc(24);
  }
}



// -------------------------  padding_tb ---/

.padding_tb {
    padding-top: rem-calc(60);
    padding-bottom: rem-calc(40);
}



/**************************************************************  toptitle */
#toptitle{
  margin-top:rem-calc(18);
  margin-bottom:rem-calc(18);

  @include breakpoint(medium) {
    margin-top:rem-calc(18);
    margin-bottom:rem-calc(80);
  }

}


// -------------------------  Slider ---/
//#slider_container{
  //height: 80vh; 
//}

.swiper-slide {
  //  height: $hero_sh_wImg-height;
  height: $hero_wImg_mobheight;
  width: 100%;
  @include breakpoint(medium) {
    height: $hero_wImg_height;
  }
}

.swiper-slide.sh {
  //  height: $hero_sh_wImg-height;
  height: $hero_sh_wImg_mobheight;

  @include breakpoint(medium) {
    height: $hero_sh_wImg_height;
  }
  picture {
    position: absolute;
    left: 0;
    bottom: 0;
  }

}

.swiper-slide-inner {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  //justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;  
  justify-content: flex-start;
  //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
  align-items: center;
  padding-top: 5%;

  @include breakpoint(medium) {
    padding-top: 5%;
  }

  .slider_title {
  display: flex;
  flex-direction: row;
  //justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;  
  justify-content: center;
  //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
  align-items: flex-start;

  color: $white;
  font-family: 'Oswald', monospace;
  font-weight: 600;
  text-shadow:  0 0 1em $col1, 0 0 0.2em $col1;
//  text-shadow:  0 0 1em rgba($black, 0.3), 0 0 0.2em rgba($black, 0.3); // for grn
//  -webkit-text-stroke: 1px rgba($white, 0.5); // for grn
  text-transform: uppercase;

  font-size: 1.8rem;
  padding: rem-calc(8 0);


  margin-top: rem-calc(12);
  margin-bottom: rem-calc(4);
  margin-left: 5%;
  margin-right: 5%;

    @include breakpoint(medium) {
      font-size: 2.0rem;
      text-align: center;
//      -webkit-text-stroke: 2px rgba($white, 0.7);      

      margin-top: rem-calc(12);
      margin-left: 10%;
      margin-right: 10%; 
    }

    @include breakpoint(large) {
      font-size: 3.6rem;
    }

    @include breakpoint(xlarge) {
      font-size: 4.0rem;
    }

    @include breakpoint(xxlarge) {
      font-size: 5.4rem;
    }

  } 
  .slider_title.grn{
    color: $col5; //
    //text-shadow:  0 0 1em $white, 0 0 0.2em $white;

  }

  p.slider_description {
  display: flex;
  flex-direction: row;
  //justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;  
  justify-content: center;
  //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
  align-items: flex-start;

  color: $white;
  font-family: 'DM Sans', sans-serif;
  text-shadow:  0 0 1em $col1, 0 0 0.2em $col1;
  font-size: 1.2rem;

  padding: rem-calc(4 0);
  margin-left: 8%;
  margin-right: 8%;

    @include breakpoint(medium) {
      font-size: 2.0rem;
      text-align: center;

      margin-top: rem-calc(6);
      margin-left: 10%;
      margin-right: 10%;
    }

    @include breakpoint(large) {
      font-size: 2.5rem;
    }

    @include breakpoint(xlarge) {
      font-size: 3.0rem;
    }

    @include breakpoint(xxlarge) {
      font-size: 3.5rem;
    }
    
  }
  .slider_description.italic{
    font-style: italic;
  }


}


/**************************************************************  widgets : widget-container */
.widget_container{
  padding-top:rem-calc(12);
  padding-bottom:rem-calc(12);

  @include breakpoint(medium) {
    padding-top:rem-calc(40);
    padding-bottom:rem-calc(40);
  }

  .text_section {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    overflow: auto; // stop overflow probs
//      margin: rem-calc(0);
    padding: rem-calc(36 24);

//    width: 100%;

    p {
      color: $white;
      //font-family: Helvetica, Arial, FreeSans, sans-serif;
      //font-size: rem-calc(48);
      margin:rem-calc(0);
      padding:rem-calc(0 8 0 8);
    }
    p.h1 {
      text-transform: uppercase;
      font-family: 'Oswald', monospace;
      font-weight: 600;

      text-decoration: underline;
      text-decoration-thickness: 2px;
    }

    @include breakpoint(medium) {
      padding: rem-calc(48 48);

    }

  }


}
// -------------------------  widget_accueil ---/
#widget_accueil{
  padding-top:rem-calc(12);
  padding-bottom:rem-calc(12);

  @include breakpoint(medium) {
    padding-top:rem-calc(40);
    padding-bottom:rem-calc(40);
  }

  .services_section_bg.serv___{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% auto;
    //background-color:rgba($col1, 0.9);

    background-image: image-set(
      "../img/parallax/parallax1.webp" type("image/webp"),
      "../img/parallax/parallax1.png" type("image/png")
    );
    
  }

  .services_section_bg.ing___{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% auto;
    background-color:rgba($col1, 0.9);

    background-image: image-set(
      "../img/parallax/parallax1.webp" type("image/webp"),
      "../img/parallax/parallax1.png" type("image/png")
    );
    
  }

}

// -------------------------  widget_actualites ---/


// -------------------------  widget_hero (I left T right) ---/
.widget_hero{
    padding-top:rem-calc(0);
    padding-bottom:rem-calc(0);

    @include breakpoint(medium) {
      padding: rem-calc(0 0);
    }

    .hero_image {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      text-align: left;
      overflow: auto; // stop overflow probs
//      margin: rem-calc(0);
      padding: rem-calc(48 48);
      
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 100% auto;
      background-color:rgba($col1, 0.9);
      width: 100%;
      height: $hero_sh_wImg_mobheight;
      
      @include breakpoint(medium) {
        height: $hero_wImg-height; //50vw;
      }

    }

    .hero_section {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      text-align: left;
      overflow: auto; // stop overflow probs
//      margin: rem-calc(0);
      padding: rem-calc(36 24);
      
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 100% auto;
      background-color:rgba($col1, 0.9);

      width: 100%;
//      height: $hero_sh_wImg_mobheight;

      p {
        color: $white;
        //font-family: Helvetica, Arial, FreeSans, sans-serif;
        //font-size: rem-calc(48);
        margin:rem-calc(0);
        padding:rem-calc(0 8 0 8);
      }
      p.h1 {
        text-transform: uppercase;
        font-family: 'Oswald', monospace;
        font-weight: 600;

//        text-decoration: underline;
        text-decoration-thickness: 2px;
      }

      @include breakpoint(medium) {
        height: $hero_wImg-height; //50vw;
        padding: rem-calc(48 48);

      }

    }

//    .hero_section.ser {
//      border-left: 1px solid $col2;
//    }
//    .hero_section.ing {
//      border-left: 1px solid $col3;
//    }

    .hero_section.ser p.h1{
      text-decoration-color: $col2;
    }
    .hero_section.ing p.h1{
      text-decoration-color: $col3;
    }
  }


// -------------------------  services ---/

#services{

  .hero_section {

    @include breakpoint(medium) {
      padding-top: 40%;
    }

  }

}


// ------------------------- widget_parallax---/
#widget_parallax{

#parallax_ingenierie{
  background-color:rgba($col1, 0.9);
  background-image: image-set(
    "../img/parallax/parallax1.webp" type("image/avif"),
    "../img/parallax/parallax1.png" type("image/png")
  );
  
}


  .parallax_bg{
    padding: rem-calc(50 0);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: 100% auto;
    position: relative;
    
    @include breakpoint(medium) {
      padding: rem-calc(100 0);
    }

  }

  .parallax_section {
    padding: rem-calc(18 0); 
    text-align: left; 
    li{
      color: $white;
    }

    p {
      color: $white;
      //font-family: Helvetica, Arial, FreeSans, sans-serif;
      //font-size: rem-calc(48);
      margin:rem-calc(0);
      padding:rem-calc(0 8 0 8);
    }
    
    p.h3 {
      text-transform: uppercase;
      font-family: 'Oswald', monospace;
      font-weight: 400;
    }

    .button {
      text-align: center;
      color: $white;

    }


  }
}

// ------------------------- widget_dark---/
.widget_dark{
  background-color:rgba($col1, 1.0);

  color: $white;
  //font-family: Helvetica, Arial, FreeSans, sans-serif;
  //font-size: rem-calc(48);
  margin:rem-calc(0);
  padding:rem-calc(0 8 0 8);
  
  .intro_bg{
    padding: rem-calc(24 0 36 0);
    
    @include breakpoint(medium) {
      padding: rem-calc(50 0 100 0);
    }

  }

  .img_bg{
    padding: rem-calc(24 0 24 0);
    
    @include breakpoint(medium) {
      padding: rem-calc(0 0 0 0);
    }
    
  }

  .text_bg{
    padding: rem-calc(24 0 24 0);
    
    @include breakpoint(medium) {
      padding: rem-calc(0 0 0 0);
    }
    
  }

//  .parallax_dark{
//    background-image: image-set(
//      "../img/parallax/parallax_white.avif" type("image/avif"),
//      "../img/parallax/parallax_white.png" type("image/png")
//    );
    
//  }

}

// ------------------------- widget_contactez_nous---/
#widget_contactez_nous{
  background-color:rgba($col3, 1.0);
}

  .contactez_nous {
    padding: rem-calc(0); 
    text-align: center; 

    a {
      color: $white;
      //font-family: Helvetica, Arial, FreeSans, sans-serif;
      //font-size: rem-calc(48);
      margin:rem-calc(0);
      padding:rem-calc(32 32);
      width: 100%;
    }
    a.h1 {
      text-transform: uppercase;
      font-family: 'Oswald', monospace;
      font-weight: 400;
    }


  }


// ------------------------- widget_nous_recruitons---/
#widget_nous_recruitons{
  background-color:rgba($col5, 1.0);
}

  .nous_recruitons {
    padding: rem-calc(0); 
    text-align: center; 

    a {
      color: $white;
      //font-family: Helvetica, Arial, FreeSans, sans-serif;
      //font-size: rem-calc(48);
      margin:rem-calc(0);
      padding:rem-calc(32 32);
      width: 100%;
    }
    a.h1 {
      text-transform: uppercase;
      font-family: 'Oswald', monospace;
      font-weight: 400;
    }


  }


/**************************************************************  Forms */
#form_contact{
  padding:rem-calc(36 0);
  caret-color: $text-light;

  .errors{
    margin-top: rem-calc(-12);
    margin-bottom: rem-calc(18);    
  display: flex;
  flex-wrap: wrap;

    i{
    color: #e4134f;
    line-height: rem-calc(14);
    font-size: rem-calc(36);
    }
    p{
    margin:0;
    padding:rem-calc(6 6 6 6);
    color: $white;
    background-color: #e4134f;
    }
  }

  p{
    color: $text-dark;
  }
  p:nth-child(1) {
      @include breakpoint(large) {
      font-size: 2rem;
    }
  }
//  p:nth-child(2) {
//
//  }
//  p:nth-child(3) {
//
//  }
  p:nth-child(4) {
    margin-bottom:rem-calc(60);
  }

  label {
    color: $text-light;

  }

  input {
    color: $white;
    border-bottom:1px solid $text-light;
    background-color: #767676;
  }

  textarea {
    color: $white;
    border-radius:0;
    border-bottom:1px solid $text-light;
    background-color: #767676;
  }

  #contactbtn {
    color: $white;
  } 

}


/**************************************************************  Contact */

#contact {
  .contact_infos {
      margin-top:rem-calc(24);
      margin-bottom:rem-calc(12);

      @include breakpoint(large) {
        margin-top: rem-calc(12);
      }
  }

  h4 {
      padding-bottom: rem-calc(18);

      @include breakpoint(large) {
        padding-bottom: rem-calc(18);
      }

      font-family: 'Montserrat', sans-serif;
      font-weight: 300;
      font-style: normal;
      color: $white;
  }

  p {
      font-family: 'Montserrat', sans-serif;
      font-weight: 200;
      font-style: normal;
  }

  p.subTitle{
  color: $medium-gray;
  margin: rem-calc(8 0 0 0);
  }

  p.entry{
  color: $text-dark;
  font-size:110%;
  margin: 0;
  }

  .icon-box{
    display: flex;
    align-items: center;
    //justify-content: space-around;

    i{
      padding-right: rem-calc(12);
    }
  }

}


/**************************************************************  #footer */
$footer-bg: $black;
$footer-anchor-color: scale-color(#fafafa, $lightness: -20%);
$footer-anchor-color-hover: #fafafa; 


#footer {
    
  padding-top:rem-calc(18);padding-bottom:rem-calc(6);
  background-color: $footer-bg;
  color: darken($text-light, 10%);


  #prefooter {

    .social{
      border-bottom: 1px solid col5;
      display: flex;
      //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
      justify-content: center;
      text-align: center;

      @include breakpoint(medium) {
        border: none;
      }
    }

    .social-assets{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: rem-calc(18 0);

      a{
        color: $footer-anchor-color;
        margin: rem-calc(0 18 0 0);

        &:hover {
          color: $footer-anchor-color-hover;
        }
      }


      @include breakpoint(medium) {
        border: none;
      }
    }

    .social-title {
      color: $white;
      margin: rem-calc(0);
      padding: rem-calc(0 18 0 0);
      text-align: center;

      @include breakpoint(medium) {
        margin-top: rem-calc(0);
        border: none;
      }
    }
    
  }

  .footer-block {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .fa {
      //font-size: 2rem;
      color: lighten($footer-bg, 45%);
    }

    p {
      margin: 0;
      margin-left: 1rem;
      //line-height: 1.125rem;
    }
  }

  .footer-notice{
    color: $white;
    text-align: center;
    margin: 0;
    padding: 0;
    //margin-left: 1rem;
    //line-height: 1.0rem;
  
      @include breakpoint(medium) {
        text-align: center;
      }
  
  }


  .footer-menu{
  flex-wrap: wrap;
  white-space: nowrap;
  //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
  justify-content: end;

  overflow: hidden;
  text-overflow: ellipsis; 
  padding-bottom: rem-calc(24);


    li{
      padding:rem-calc(0 0 16 0);
      a{
        color: $anchor-color;//lighten($footer-bg, 45%);
        padding:rem-calc(0 6 0 6);
        line-height: 1.6;
      }
      &:hover a{
        color: $anchor-color-hover;
      }
         
    }

  }

  .up-arrow{
  color: $white;
  width: 100%;

  display: flex;
  justify-content: flex-end;

      a{
        color: $white;
        padding:rem-calc(6 6 3 6);
        &:hover {
          color: $anchor-color-hover;
        }          
      } 

  }

}


/************************************************************** OFFLINE */

